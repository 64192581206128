@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  height: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('./components/image/SPCphone.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

@media (min-width: 800px) {
  body, html {
    height: 100%;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),  url('./components/image/SPCbackground.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
}

input[type="file"] {
  display: none;
}

th, td { min-width: 200px; }

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.thankyou {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.xxs {
  font-size: 0.625rem; /* 12px */
line-height: 0.75rem; /* 16px */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.special {
  font-family: 'Pacifico', cursive;
}

.nicer {
  font-family: 'Abril Fatface', sans-serif;
}

.line {
  display: block;
  width: 50px;
  height: 5px;
  margin: 10px auto;
}

.sttelemedia {
  font-family: 'Lato', sans-serif;
}

.screen {
  font-family: 'Abril Fatface', cursive;
  font-family: 'Lato', sans-serif;
  font-family: 'Pacifico', cursive;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
}
